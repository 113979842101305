import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Google’s `}<a parentName="p" {...{
        "href": "https://dialogflow.com/"
      }}>{`Dialogflow`}</a>{` is one of the popular development tools used by chatbot builders to craft conversations for Google Assistants and various other platforms like Alexa, Slack, Facebook Messenger, or Twillio.`}</p>
    <p>{`Many Dialogflow users choose to go with Cloud Functions for `}<a parentName="p" {...{
        "href": "https://firebase.google.com/"
      }}>{`Firebase`}</a>{` for the fulfillment of their Agent: it is totally integrated to Dialogflow’s code editor and allows the agent to run serverless.`}</p>
    <p>{`Integrating Dashbot into your Dialogflow Agent is quick and pain free – for beginners and code wizards alike.`}</p>
    <p>{`Here’s how to manage a successful integration in less than 3 minutes.`}</p>
    <h2>{`Pre-requisites`}</h2>
    <p>{`Before integrating Dashbot to your Dialogflow Agent on Firebase, you must have:`}</p>
    <ul>
      <li parentName="ul">{`Built an Agent on `}<a parentName="li" {...{
          "href": "https://console.dialogflow.com"
        }}>{`Dialogflow`}</a></li>
      <li parentName="ul">{`Added your Agent as a `}<a parentName="li" {...{
          "href": "https://console.firebase.google.com"
        }}>{`Firebase`}</a>{` project`}</li>
      <li parentName="ul">{`Created an account with `}<a parentName="li" {...{
          "href": "https://reports.dashbot.io/signup"
        }}>{`Dashbot`}</a></li>
    </ul>
    <h2>{`Easy 2-step process`}</h2>
    <p><strong parentName="p">{`1. In your Dashbot account,`}</strong>{` `}<a parentName="p" {...{
        "href": "https://reports.dashbot.io/account/bots/add"
      }}><strong parentName="a">{`add the Agent`}</strong></a>{` `}<strong parentName="p">{`to get its API key`}</strong>{` Type in the agent name and select Google as the platform from the dropdown list.`}<br parentName="p"></br>{`
`}{`The Category and Product Status are optional.`}<br parentName="p"></br>{`
`}{`Click Register. This will open a new page on which you will find the API key that will be used to identify your agent when Dashbot and Firebase communicate.`}</p>
    <p><strong parentName="p">{`2. In Dialogflow’s fulfillment menu, enable the Inline Editor`}</strong></p>
    <p><strong parentName="p">{`a. In the package.json tab, add Dashbot to the list of dependencies`}</strong><br parentName="p"></br>{`
`}{`Copy the line in blue and past it at the bottom of the dependencies list.`}</p>
    <p><inlineCode parentName="p">{`<font color="#ffffff">"dependencies": {<br></br>...<br></br></font><strong><font color="#00F5FF">const "dashbot": "^11.1.0"</font></strong><br></br><font color="#ffffff">}</font>`}</inlineCode></p>
    <p><strong parentName="p">{`b. In the index.js tab`}</strong></p>
    <p><strong parentName="p">{`i. Define the Dashbot constant`}</strong><br parentName="p"></br>{`
`}{`Copy the following line in blue and paste below in your list of constants.`}<br parentName="p"></br>{`
`}{`Replace the red text ‘DASHBOT_API_KEY’ with the API Key generated in Step 1 above.`}</p>
    <p><inlineCode parentName="p">{`<font color="#ffffff">'use strict';<br></br>const functions = require ('firebase-functions');<br></br>const {dialogflow} = require ('actions-on-google');<br></br><br></br></font><strong><font color="#00F5FF">const dashbot = require ('dashbot')(<font color="#FF0000">'DASHBOT_API_KEY</font>).google;</font></strong>`}</inlineCode></p>
    <p><strong parentName="p">{`ii. After defining your Agent constant, pass the Agent object to the Dashbot handler`}</strong><br parentName="p"></br>{`
`}{`This will enable the data to be sent to Dashbot.`}<br parentName="p"></br>{`
`}{`Make sure the name in brackets matches the name you gave to your agent.`}<br parentName="p"></br>{`
`}{`By default, Dialogflow calls your Agent “agent.” If you wrote the code yourself in the Inline Editor, use the name you chose for your Agent. For example, if you called it “app,” it would look like this.`}</p>
    <p><inlineCode parentName="p">{`<font color="#ffffff">const app = dialogflow();</font><br></br><strong><font color="#00F5FF">dashbot.configHandler(app);</font></strong><br></br><font color="#ffffff">...<br></br>// business logic <br></br>...<br></br>exports.dialogflowFirebaseFulfillment = functions.https.onRequest(app);</font>`}</inlineCode></p>
    <p><strong parentName="p">{`Hit “Deploy”`}</strong>{` and that’s it!`}</p>
    <p>{`These 3 lines of code are also available for an easy copy-paste:`}</p>
    <ul>
      <li parentName="ul">{`On the Dashbot page you registered your bot on. Just scroll down the page.`}</li>
      <li parentName="ul">{`On the documentation at `}<a parentName="li" {...{
          "href": "https://www.dashbot.io/docs/google/firebase"
        }}>{`https://www.dashbot.io/docs/google/firebase`}</a></li>
    </ul>
    <h2>{`Get instant confirmation on the success of your integration`}</h2>
    <p>{`Send your Agent a test message.`}<br parentName="p"></br>{`
`}{`You can do so in Dialogflow by typing a message into the “Try it now” console in the upper right corner of the page. Alternatively you can open the simulator for the Action and send messages there.`}</p>
    <p>{`Go to your Dashbot’s page. Click the “Verify” button to check if data is coming through.`}</p>
    <figure className="wp-block-image is-resized">
  ![](https://lh4.googleusercontent.com/vAR8yEiCxIpcocOknXFVXDbGvsRW5HFdztbzhyjjT5C-Yse2i368AIW-OQexFSE3jZFchg3A7ry3Jb-pj80h2J0au2pTIriuKwnqLchAWdk3Y5IE3zNbiNi40p85s3HJ6vH7182U)
    </figure>
If the integration was successful, you will see the confirmation below:
    <figure className="wp-block-image is-resized">
  ![](https://lh4.googleusercontent.com/pbFjc4r1GpyJtlnx8jqygfP__P3RYl7Fdasp3-UBM-2qiwcZv9mWWLddOqzd2-jxXQZDy2ytWvGWibC894QgS9O5-k32-kQaeyb2E4FxtDLLRJrr_x6hLqv8p5KQ-pvGmXblrP49)
    </figure>
If you see the message below, Dashbot is not yet receiving data from your Agent.
    <figure className="wp-block-image is-resized">
  ![](https://lh5.googleusercontent.com/hFlGbkcj2jwsEB7_VO9ZLTTw1s6VQs00vXRaqeuBZRi1xwaVGQds-9yM77m22NvWO67pHFhc4AJgULfmZplVMBO0HWbT-t_5iXaNgNIHUk_8EDkVqOGWNKATGFB30naeWCbSbaKX)
    </figure>
Here are the most common reasons for this:
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Your Agent has been inactive for too long. As suggested in the error message, send a message to your Agent and try again.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Webhook calls are not enabled for Fullfillment at the Intent level in Dialogflow. Go back to Dialogflow, scroll down each Intent page all the way to the Fulfillment section and enable the webhook call.`}</p>
      </li>
    </ul>
    <h2><figure className="wp-block-image is-resized"><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2019/08/14120914/Screen-Shot-2019-08-14-at-12.08.34-PM-1024x578.png",
          "alt": null
        }}></img></figure>{`View your Reports`}</h2>
    <p>{`After successfully integrating Dashbot, check out your reports by clicking View Reports on the chatbot page, or by selecting the chatbot from the Bots/Skills drop down.`}</p>
    <p>{`Click the Real Time section in the left nav. From here, select any of the real-time options to see the data coming into your chatbot.`}</p>
    <p>{`Please note, some of the other, non-real-time reports in the dashboard are rolled up on time intervals. If you do not see data initially in those reports, check back in an hour to see them fill in.`}</p>
    <p>{`For more information about our reports and to view samples, please check out our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`tour`}</a>{`.`}</p>
    <h2>{`Insider’s tip`}</h2>
    <p>{`With Dashbot’s Unhandled Report, you can quickly see where the NLP is failing to understand the user, and use that information to retrain your bot on Dialogflow.`}<br parentName="p"></br>{`
`}{`Make sure you configure your Fallback Intent for this report to populate correctly.`}<br parentName="p"></br>{`
`}{`By default, Dashbot calls this Fallback Intent “NotHandled.” By default, Dialogflow calls this Intent the “Default Fallback Intent.”`}</p>
    <p>{`Go to your account page and click the settings button for the relevant bot. Once on the bot settings page, click the Edit button.`}<br parentName="p"></br>{`
`}{`Next to “Not Handled Intent Name”, select from the dropdown list the Intent that corresponds to your Fallback Intent. Save and check out the reports.`}</p>
    <figure className="wp-block-image is-resized">
  ![](https://lh6.googleusercontent.com/0oLN-Vj0eHm9ibM189R-9Rt_xqNIn0B6LshjiK-gFNN1f0-O5F45FBcELJ87b_I3V4t7NnjdHc09QHo84Ok_TwOIDoV49C4HcscjHBvRE2R1i2iXSgkmmVm_KGkAaqx4Fb3smqGh)
    </figure>
Please note, if you do not see the Intent you want to use in the dropdown, wait
24 hours and check again. Our daily batch process populates the list with all of
your Intents.
    <h2>{`Have any questions?`}</h2>
    <p>{`Need help with the integration? Want to know more about Dashbot? `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/contact"
      }}>{`Let us know!`}</a></p>
    <p>{`Are you developing with other tools or for other platforms? Please check out our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs"
      }}>{`Documentation`}</a>{` for other integrations.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables enterprises and developers to increase user engagement, acquisition, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide conversation specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      